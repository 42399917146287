import * as React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import portrait_slider_1 from "../images/portrait-enhancement.webp"
import portrait_slider_2 from "../images/color-tuning.webp"
import portrait_slider_3 from "../images/background-editing.webp"
import portrait_slider_4 from "../images/lighting-adjustments.webp"
import portrait_slider_5 from "../images/resizing-and-cropping.webp"
import portrait_slider_6 from "../images/custom-color-grading.webp"
import { Trans } from "gatsby-plugin-react-i18next"

const SimpleSlider = () => {
  function SampleNextArrow(props) {
    const { onClick } = props
    return <div className="cut-arrow-right" onClick={onClick} />
  }

  function SamplePrevArrow(props) {
    const { onClick } = props
    return <div className="cut-arrow-left" onClick={onClick} />
  }
  const settings = {
    infinite: true,
    className: "center",
    infinite: true,
    dots: true,
    arrows: true,
    centerMode: true,
    centerPadding: "80px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      <div>
        <div className="slider-main">
          <Slider {...settings}>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={portrait_slider_1}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Portrait Enhancement</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Detailed portrait retouching, contrast adjustments, red-eye reduction etc
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={portrait_slider_2}
                    alt="fashion-slider-img-2"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Color Tuning</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Precise color corrections are guaranteed to make your portraits stand out
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={portrait_slider_3}
                    alt="fashion-slider-img-3"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Background Editing</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Transforming ordinary or boring backgrounds into vibrant and dynamic ones
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={portrait_slider_4}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Lighting Adjustments</Trans>
                    </h2>
                    <p>
                      <Trans>
                      The addition of artificial shadows cast shadows and drop shadows  
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={portrait_slider_5}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Resizing and Cropping</Trans>
                    </h2>
                    <p>
                      <Trans>
                      Scaling adjustments, including cropping and ratio modifications
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cate-slider-box">
                <div className="back-color-slider-box">
                  <img
                    src={portrait_slider_6}
                    alt="fashion-slider-img-1"
                    width="554px"
                    height="552px"
                  ></img>
                  <div className="fh-text">
                    <h2>
                      <Trans>Custom Color Grading</Trans>
                    </h2>
                    <p>
                      <Trans>
                      High-resolution color correction and enhancement  
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  )
}
export default SimpleSlider
