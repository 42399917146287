import React, { useEffect, useState } from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import fashion_1 from "../images/portrait-intro-img.webp"
import fashion_1_mob from "../images/portrait-intro-img.webp"
import Testimonials from "../components/Testimonials.js"
import Portrait_Slider from "../components/Portrait_Slider.js"
import Sucslider from "../components/success_slider.js"
import fashion_icon_1 from "../images/quality.webp"
import fashion_icon_2 from "../images/turn-around.webp"
import fashion_icon_3 from "../images/customer-satisfation.webp"
import fashion_icon_4 from "../images/expert.webp"
import fashion_2 from "../images/portrait-bottom-img.webp"
import fashion_2_mob from "../images/portrait-bottom-img.webp"
import portrait_slider_1 from "../images/portrait-enhancement.webp"
import portrait_slider_2 from "../images/color-tuning.webp"
import portrait_slider_3 from "../images/background-editing.webp"
import portrait_slider_4 from "../images/lighting-adjustments.webp"
import portrait_slider_5 from "../images/resizing-and-cropping.webp"
import portrait_slider_6 from "../images/custom-color-grading.webp"
import { SuccessStoryData } from "../data/fashionSuccessStoryData.js"
import { TestimonialData } from "../data/fashionTestimonialData.js"
import setKey, { FirstCapital } from "../utils/setKey.js"
import { Trans, useI18next, Link } from "gatsby-plugin-react-i18next"
import { navigate, graphql } from "gatsby"

var Fashion_keyword = "Outsource Portrait Photo Editing"

const Portrait= ({ location, ...rest }) => {
  const { languages, changeLanguage } = useI18next()

  const [dataKey, setDataKey] = useState("")
  const [locationKey, setLocationKey] = useState("")
  const [classname, setClassname] = useState("")

  useEffect(() => {
    typeof window !== "undefined" &&
    localStorage.getItem("gatsby-i18next-language") === "de"
      ? setClassname("de")
      : setClassname("")
  })

  useEffect(async () => {
    let data = await setKey(location, "utm_term_fashion", Fashion_keyword)
    if (data.length > 0) {
      setDataKey(data[0])
      setLocationKey(data[1])
    }

    return () => {
      data = null
    }
  }, [])

  useEffect(() => {
    if (locationKey === "Germany") {
      changeLanguage(languages[2])
    } else {
      if (
        rest.pageContext.language === "de" &&
        localStorage.getItem("location") !== "Germany"
      ) {
        localStorage.setItem("gatsby-i18next-language", "en")
        navigate("/outsource-portrait-photo-editing")
      }
    }
  }, [locationKey])

  return (
    <>
      <Header
        metaTitle={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Porträtfotobearbeitung | Outsourcing der Fotobearbeitung"
            : "Portrait Photo Editing | Photo Editing Outsourcing"
        }
        metaDescription={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Wir sind auf die Auslagerung der Bearbeitung von Porträtfotos spezialisiert und bieten mit unserem umfassenden Fachwissen, modernsten Tools und einem Team erfahrener Fachleute erstklassige Dienstleistungen"
            : "We specialize in Outsource Portrait Photo Editing, with our deep expertise, cutting-edge tools, & a team of seasoned professionals to provide top-tier services"
        }
        metakeyword={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Porträtfotobearbeitung, Fotobearbeitungs-Outsourcing, Fotobearbeitungsdienste"
            : "portrait photo editing, Photo Editing Outsourcing, Photo Editing Services"
        }
        canOnical="https://www.photoeditingoutsourcing.com/outsource-portrait-photo-editing/"
      />

      <div className="home-sec">
        <div className="zindex-div">
          <div className="product-sec fashion-sec">
            <div className="ps-first">
              <div className="container">
                <div className="home-first-sec">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className={`text-box ${
                          classname === "de" ? "text-box-german" : ""
                        }`}
                      >
                        <h1>
                          <Trans>
                            {dataKey && dataKey
                              ? FirstCapital(dataKey)
                              : Fashion_keyword}
                          </Trans>
                        </h1>
                        <p>
                          <Trans>
                          Amplify your photographic prowess with Photo Editing Outsourcing's exemplary portrait photo editing services.
                          </Trans>
                        </p>
                        <div className="btn-home-sec">
                          <Link to="/contact-us" className="get-started">
                            <Trans>Get started</Trans>
                          </Link>
                          <Link to="/portfolio?tab=1" className="see-port">
                            <Trans>see portfolio </Trans>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="homeimg-box">
                        <img
                          src={fashion_1}
                          alt="fashion-banner-img"
                          className="img-1 desktop-img-1"
                          width="488px"
                          height="578px"
                        ></img>
                        <img
                          src={fashion_1_mob}
                          alt="fashion-banner-img-mob"
                          className="mob-img-1"
                          width="244px"
                          height="289px"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-3-sec">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 right-len">
                    <div className="row row-2">
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            {" "}
                            <img
                              src={fashion_icon_1}
                              alt="fashion-icon-img-1"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Quality Assurance</Trans>
                          </h1>
                          <p>
                            <Trans>
                            High-quality, eye-catching portrait edits.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            {" "}
                            <img
                              src={fashion_icon_2}
                              alt="fashion-icon-img-2"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Quick Turnaround</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Fast delivery with minimal business disruption.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            {" "}
                            <img
                              src={fashion_icon_3}
                              alt="fashion-icon-img-3"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Customer Satisfaction</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Committed to deliver personalized service.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            {" "}
                            <img
                              src={fashion_icon_4}
                              alt="fashion-icon-img-4"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Industry Expertise</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Specialized skills in portrait photo editing.
                            </Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 our-mission">
                    <div className="row  align-items-center">
                      <div className="col-md-12 col-lg-6">
                        <div className="cat-slider-text">
                          <h4>
                            <Trans>PICTURE PERFECT</Trans>
                          </h4>
                          <h2>
                            <Trans>
                            Your Premier Outsourcing Partner for Portrait Photo Editing
                            </Trans>
                          </h2>
                          {typeof window !== "undefined" &&
                          localStorage.getItem("gatsby-i18next-language") ===
                            "de" ? (
                            <p>
                              <Trans>Portrait&fashiondesc</Trans>
                            </p>
                          ) : (
                            <p>
                              At Photo Editing Outsourcing, we leverage the very best in technology to provide you with superior portrait photo editing services. Our team utilizes advanced tools and software, such as Adobe Photoshop and Lightroom, to refine every pixel to perfection. We enhance colors, perfect skin tones, adjust lighting, and intricately edit each element to transform your portraits into stunning works of art.
                            </p>
                          )}
                          <p>
                            <Trans>
                            Our portrait photo editors understand the struggle of meeting tight deadlines with quality editing. With our professional portrait photo editing services, we blend technical expertise with an artistic touch, delivering edited portraits that truly stand out. Partner with us and Discover how we can transform your editing process and create stunning results.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="img-box-about">
                          <img
                            src={fashion_2}
                            alt="fashion-img-2"
                            className="desktop-img-1"
                            width="604px"
                            height="449px"
                          ></img>
                          <img
                            src={fashion_2_mob}
                            alt="fashion-img-2-mob"
                            className="mob-img-1"
                            width="333px"
                            height="250px"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="abot-2-sec secound-ab">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6">
                    <p>
                      <Trans>With over 15 years of editing experience, we have the skills and tools to help photographers and studio houses achieve photographic excellence, freeing up your time to focus on capturing moments without worrying about editing. </Trans>
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <p>
                      <Trans>
                      Photo Editing Outsourcing is your trusted partner for advanced portrait photo editing services. Our photo editing services can transform a good portrait into a captivating masterpiece. 
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="fashion-slider">
              <div className="cat-slider-text">
                <h4>
                  <Trans>Potrait Photo Editing Services </Trans>
                </h4>
                <h2
                  className={`fashion-photo-service ${
                    classname === "de" && "fashion-photo-service-german"
                  }`}
                >
                  <Trans>Crafting Visual Excellence</Trans>
                </h2>
                <p>
                  <Trans>
                  Experience Precision-focused photo editing services with Photo Editing Outsourcing. We combine cutting-edge technology <br>and expert skills to deliver stunningly edited portraits, on time, every time.</br>
                  </Trans>
                </p>
              </div>
              <div className="slider-sh">
                <Portrait_Slider />
              </div>

              <div className="fh-slider-mob">
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={portrait_slider_1}
                      alt="fashion-slider-4-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Portrait Enhancement</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Detailed portrait retouching, contrast adjustments, red-eye reduction etc
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={portrait_slider_2}
                      alt="fashion-slider-5-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Color Tuning</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Precise color corrections are guaranteed to make your portraits stand out
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={portrait_slider_3}
                      alt="fashion-slider-1-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Background Editing</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Transforming ordinary or boring backgrounds into vibrant and dynamic ones
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={portrait_slider_4}
                      alt="fashion-slider-2-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Lighting Adjustments</Trans>
                      </h2>
                      <p>
                        <Trans>
                        The addition of artificial shadows cast shadows and drop shadows  
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={portrait_slider_5}
                      alt="fashion-slider-3-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Resizing and Cropping</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Scaling adjustments, including cropping and ratio modifications
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={portrait_slider_6}
                      alt="fashion-slider-6-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Custom Color Grading</Trans>
                      </h2>
                      <p>
                        <Trans>
                        High-resolution color correction and enhancement  
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="how-sec">
              <div className="container">
                <h2>
                  <Trans>Photo Editing Outsourcing</Trans>
                </h2>
                {typeof window !== "undefined" &&
                localStorage.getItem("gatsby-i18next-language") === "de" ? (
                  <p className="how-germen-para-text">
                    <Trans>workingdesc</Trans>
                  </p>
                ) : (
                  <p>
                    <Trans>
                    Outsource your portrait photo editing needs to us and bring life to your images.
                    </Trans>
                  </p>
                )}
              </div>
            </div>
            <div className="category-slider-sec testimonials-sec ecommerce-testi">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="cat-slider-text">
                      <h4>
                        <Trans>Word of praise</Trans>
                      </h4>
                      <h3>
                        <Trans>Testimonials</Trans>
                      </h3>
                      <p>
                        <Trans>
                          Don’t just take our word for it. Discover what our
                          customers have to say about us.
                        </Trans>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>
              <div className="main-slider">
                <div className="container cate-slider">
                  <Testimonials
                    locationKey={locationKey}
                    TestimonialData={TestimonialData}
                  />
                </div>
              </div>
            </div>
            <div className="Success-sec">
              <div className="container">
                <div className="cat-slider-text">
                  <h4>
                    <Trans>Success</Trans>
                  </h4>
                  <h3>
                    <Trans>Success Stories</Trans>
                  </h3>
                  <p>
                    <Trans>
                      Delivering true value to every customer. Find out how we
                      do it.
                    </Trans>
                  </p>
                </div>
                <div className="main-slider">
                  <div className="container cate-slider">
                    <Sucslider
                      locationKey={locationKey}
                      SuccessStoryData={SuccessStoryData}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <GetStart
              dataKey={FirstCapital(dataKey)}
              pageKeyword={Fashion_keyword}
            /> */}
            <div className="start-sec">
              <div className="container">
                <div className="row  align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="start-text">
                        <h1>
                          <Trans>Outsource Portrait Photoediting Services</Trans>
                        </h1>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="start-btn">
                      <Link to="/contact-us">
                        <button>
                          <Trans>Get started</Trans>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Portrait

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
